<template>
  <q-page padding>
    <div class="row">
      <q-table
        title="Autor"
        :rows="authors"
        :columns="columnsAuthors"
        row-key="id"
        class="col-12"
        :loading="loading"
      >
        <template v-slot:top>
          <span class="text-h6">Autor</span>
          <q-space />
          <q-btn
            v-if="$q.platform.is.desktop"
            dense
            icon="add"
            alt="Adicionar autor"
            color="positive"
            size="sm"
            :to="{name: 'form-author'}"
          >
            <q-tooltip>
              Adicionar autor
            </q-tooltip>
          </q-btn>
        </template>
        <template v-slot:body-cell-img_url="props">
          <q-td :props="props">
            <q-avatar color="primary">
              <img v-if="props.row.img_url" :src="props.row.img_url" :ratio="1">
              <span v-else > {{props.row.name.toString().split('')[0] }}</span>
            </q-avatar>
          </q-td>
        </template>
        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <div class="q-gutter-x-md">
              <q-btn
                icon="edit"
                alt="Editar"
                color="info"
                dense
                size="sm"
                @click="handleEditAuthor(props.row)"
              >
                <q-tooltip>
                  Editar author
                </q-tooltip>
              </q-btn>
              <q-btn
                icon="delete"
                alt="Remover"
                color="negative"
                dense
                size="sm"
                @click="handleRemoveAuthor(props.row)"
              >
                <q-tooltip>
                  Remover autor
                </q-tooltip>
              </q-btn>
            </div>
          </q-td>
        </template>
      </q-table>
    </div>
    <q-page-sticky
      position="bottom-right"
      :offset="[18, 18]"
      v-if="$q.platform.is.mobile"
    >
      <q-btn
        fab
        icon="add"
        color="positive"
        padding="md"
        :to="{name: 'form-author'}"
      >
        <q-tooltip>
          Adicionar autor
        </q-tooltip>
      </q-btn>
    </q-page-sticky>
  </q-page>
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue'
import useApi from '../../composables/UseApi'
import useNotify from '../../composables/UseNotify'
import { useRouter } from 'vue-router'
import { useQuasar } from 'quasar'
import { columnsAuthors } from './table'

export default defineComponent({
  name: 'PageListTemas',

  setup () {
    const table = 'Autor'
    const authors = ref([])
    const router = useRouter()
    const { list, remove } = useApi()
    const { notifyError, notifySuccess } = useNotify()
    const loading = ref(true)
    const $q = useQuasar()

    const handleListAuthors = async () => {
      try {
        loading.value = true
        authors.value = await list(table)
        loading.value = false
      } catch (error) {
        notifyError(error.message)
      }
    }

    const handleEditAuthor = (author) => {
      router.push({ name: 'form-author', params: { id: author.id } })
    }

    const handleRemoveAuthor = async (author) => {
      try {
        $q.dialog({
          title: 'Confirme',
          message: `Você realmente deseja remover  ${author.name}?`,
          cancel: true,
          persistent: true
        }).onOk(async () => {
          try {
            await remove(table, author.id)
            notifySuccess('Autor removido com sucesso!')
            handleListAuthors()
          } catch (error) {
            notifyError(error.message)
          }
        })
      } catch (error) {
        notifyError(error.message)
      }
    }

    onMounted(async () => {
      handleListAuthors()
    })

    return { columnsAuthors, authors, loading, handleEditAuthor, handleRemoveAuthor }
  }
})
</script>
