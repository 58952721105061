const columnsAuthors = [
  { name: 'img_url', label: 'Foto', field: 'img_url', sortable: false },
  { name: 'name', align: 'left', label: 'Nome', field: 'name', sortable: true },
  { name: 'description', label: 'Descrição', field: 'description', sortable: true },
  { name: 'actions', label: 'Ações', field: 'actions', sortable: false }
]

export {
  columnsAuthors
}
